/* 
 * @Author: 曹俊杰 
 * @Date: 2021-03-23 15:25:13
 * @Module: 常设机构echarts 
 */
 <template>
  <div class="box">
    <div id="myChart"
         :style="{width: '760px',height: '583px'}"></div>
  </div>
</template>
 <script>
import echarts from 'echarts'
import 'echarts/map/js/china.js';
export default {
  components: {},
  data () {
    return {
    };
  },
  mounted () {
    this.drawLine();
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      const option = {
        dataset: {
          "source": [
            ["省份", "大区名", "大区名"],
            ["河南", "网红头条总部", "96", "800", "郑州运营中心"],
            ["辽宁", "沈阳运营中心", "96", "61", ""],
            ["重庆", "重庆运营中心", "34", "69", ""],
            ["浙江", "嘉兴运营中心", "34", "69", "衢州运营中心"],
          ]
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            return params.name + '<br/>' + params.data[1] + "<br/>" + params.data[4];
          }
        },
        // visualMap: {
        // type: 'piecewise',
        // left: '15',
        // bottom: '15',
        // itemWidth: 27,
        // itemHeight: 15,
        // textStyle: {
        //   color: '#333333',
        //   fontSize: 14,
        // },
        // pieces: [{
        //   min: 500,
        //   label: '>500',
        // }, {
        //   max: 500,
        //   min: 200,
        //   label: '200-500',
        // }, {
        //   max: 200,
        //   min: 0,
        //   label: '<200',
        // }, {
        //   value: 0,
        //   label: '无数据',
        // },],
        //   inRange: {
        //     color: ['#B2CAE0', '#D2EAFF', '#8AC6FD', '#45A5F8']
        //   },
        //   outOfRange: {
        //     color: ['#999999']
        //   }
        // },
        geo: {
          map: 'china',
          show: true,
          roam: false,
          label: {
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0,63,140,0.2)',
              shadowColor: 'rgba(0,63,140,0.2)',
              shadowOffsetY: 20,
              shadowBlur: 30
            }
          }
        },
        series: [{
          type: 'map',
          map: 'china',
          aspectScale: 0.75,
          //zoom:1.1,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#B2CAE0',
              borderColor: '#fff',
              borderWidth: 1,
            },
            emphasis: {
              areaColor: '#45A5F8',
            }
          },
          // data: outdata
        }]
      };
      myChart.setOption(option);
      this.int(myChart)
    },
    int (myChart) {
      var count = 0;
      var timeTicket = null;
      var dataLength = 4;
      timeTicket && clearInterval(timeTicket);
      timeTicket = setInterval(() => {
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
        });
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: (count) % dataLength
        });
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: (count) % dataLength
        });
        count++;
      }, 1000);
    }
  },
};
 </script>
 <style lang='scss' scoped>
.box {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>